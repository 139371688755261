import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass']
})
export class NavbarComponent implements OnInit {

  @ViewChild('close') closeButton!: ElementRef<HTMLElement>;
  @Input() showLogo: boolean = false;
  @Input() logoSize?: string;

  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
  }

  public onClick(elementId: string): void { 
    this.viewportScroller.scrollToAnchor(elementId);
  }

  public clickAndClose(elementId: string): void {
    let closeButton: HTMLElement = this.closeButton.nativeElement;
    closeButton.click();
    this.viewportScroller.scrollToAnchor(elementId);
  }

}
