import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.sass']
})
export class BaseComponent implements OnInit {

  @Input() showLogo: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
