import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.sass']
})
export class MusicComponent implements OnInit {

  public songs = [
    {title:'Si te Largaste', author:'Nathan Solis', time:'2:50', active: true},
    {title:'Amor de Borrachera', author:'Nathan Solis', time:'Próximamente', active: false},
    {title:'Si el Tequila Hablara', author:'Nathan Solis', time:'Próximamente', active: false},
    {title:'Descaradamente', author:'Nathan Solis', time:'Próximamente', active: false},
    {title:'Ni que Fueras Tequila', author:'Nathan Solis', time:'Próximamente', active: false},
    {title:'Nunca Vuelvas', author:'Nathan Solis', time:'Próximamente', active: false},
    {title:'¿Como te va mi amor?', author:'Nathan Solis, Hernaldo Zúñiga', time:'Próximamente', active: false},
    {title:'Belleza de Cantina', author:'Nathan Solis, Antonio Ferlun', time:'Próximamente', active: false},
  ]

  public isPlaying: boolean = false;

  @ViewChild('player') player!: ElementRef<HTMLElement>;

  constructor() {
    const body = document.body;
    body.classList.remove("removeBackground");
  }

  ngOnInit(): void {
  }

  public playAudio(): void {
    const player = <HTMLAudioElement> this.player.nativeElement;
    player.play();
    this.isPlaying = true;
  }

  public pauseAudio(): void {
    const player = <HTMLAudioElement> this.player.nativeElement;
    player.pause();
    this.isPlaying = false;
  }

  public changeVolume(volume:any): void {
    const player = <HTMLAudioElement> this.player.nativeElement;
    const newVolume = volume * 0.1;
    player.volume = newVolume;
  }

  public changeSongMoment(moment:any): void {
    const player = <HTMLAudioElement> this.player.nativeElement;
    const newVolume = moment;
    player.currentTime = newVolume;
  }  

}
