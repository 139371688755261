import { RouterModule, Routes } from "@angular/router";
import { BioComponent } from "./bio/bio.component";
import { ContactComponent } from "./contact/contact.component";
import { HomeComponent } from "./home/home.component";
import { LandingComponent } from "./landing-pages/landing/landing.component";
import { MarketComponent } from "./market/market.component";
import { MusicComponent } from "./music/music.component";
  
  const APP_ROUTES: Routes = [
    { path: "home", component: HomeComponent },
    { path: "bio", component: BioComponent },
    { path: "music", component: MusicComponent },
    { path: "market", component: MarketComponent },
    { path: "contact", component: ContactComponent },
    { path: "landing", component: LandingComponent },
    { path: "", redirectTo: "/home", pathMatch: "full" },
    { path: "**", redirectTo: "home", pathMatch: "full" }
  ];
  
  export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES,{
    anchorScrolling: 'enabled'
  });