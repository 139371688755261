import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bio',
  templateUrl: './bio.component.html',
  styleUrls: ['./bio.component.sass']
})
export class BioComponent implements OnInit {

  constructor() {
    const body = document.body;
    body.classList.remove("removeBackground");
  }

  ngOnInit(): void {
  }

}
