<div class="navbar-container">
    <div class="header">
        <div class="left">
            <div class="logo-min">
                <img class="logo-s" logoSize src="assets/img/nathanLogoBig.png">
            </div>
        </div>
        <div class="right">
            <div class="menu">
                <div class="option" (click)="onClick('home')">Inicio</div>
                <div class="option" (click)="onClick('bio')">Biografia</div>
                <div class="option" (click)="onClick('music')">Música</div>
                <div class="option">
                    <a href="https://open.spotify.com/artist/0cB5P2JVH6sZw9uA08dOPQ?si=FK6UEXLwR-OJHhb9EbHEjw" target="_blank">
                        <div class="sm">
                            <img class="logo" src="assets/img/icons/spotify.png">
                        </div>
                    </a>
                    <a href="https://www.youtube.com/channel/UCERl_WmQbBOIQVlThoebYJw" target="_blank">
                        <div class="sm">
                            <img class="logo" src="assets/img/icons/youtube.png">
                        </div>
                    </a>
                    <a href="https://www.instagram.com/nathansolisoficial/" target="_blank">
                        <div class="sm">
                            <img class="logo" src="assets/img/icons/instagram.png">
                        </div>
                    </a>
                    <a href="https://twitter.com/NatSolisOficial" target="_blank">
                        <div class="sm">
                            <img class="logo" src="assets/img/icons/twitter.png">
                        </div>
                    </a>
                    <a href="https://www.facebook.com/NathanSolisOficial/" target="_blank">
                        <div class="sm">
                            <div class="sm"><img class="logo" src="assets/img/icons/facebook.png"></div>
                        </div>
                    </a>
                </div>
                <input type="checkbox" id="active">
                <label for="active" class="menu-btn"><span></span></label>
                <label for="active" class="close" #close></label>
                <div class="wrapper">
                    <ul>
                        <li (click)="clickAndClose('home')">Inicio</li>
                        <li (click)="clickAndClose('bio')">Biografia</li>
                        <li (click)="clickAndClose('music')">Música</li>
                        <li class="social-icons">
                            <a href="https://open.spotify.com/artist/0cB5P2JVH6sZw9uA08dOPQ?si=FK6UEXLwR-OJHhb9EbHEjw" target="_blank">
                                <div class="sm">
                                    <img class="logo" src="assets/img/icons/spotify.png">
                                </div>
                            </a>
                            <a href="https://www.youtube.com/channel/UCERl_WmQbBOIQVlThoebYJw" target="_blank">
                                <div class="sm">
                                    <img class="logo" src="assets/img/icons/youtube.png">
                                </div>
                            </a>
                            <a href="https://www.instagram.com/nathansolisoficial/" target="_blank">
                                <div class="sm">
                                    <img class="logo" src="assets/img/icons/instagram.png">
                                </div>
                            </a>
                            <a href="https://twitter.com/NatSolisOficial" target="_blank">
                                <div class="sm">
                                    <img class="logo" src="assets/img/icons/twitter.png">
                                </div>
                            </a>
                            <a href="https://www.facebook.com/NathanSolisOficial/" target="_blank">
                                <div class="sm">
                                    <div class="sm"><img class="logo" src="assets/img/icons/facebook.png"></div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>