<app-base>
    <div class="container-component">
        <div class="filters">
            <h1 class="subtitle">Categorias</h1>
            <p class="selection">Selecionar Todos</p>
        </div>
        <div class="products">
    
        </div>
        <div class="shopping-cart">
            <h1 class="subtitle">Carrito de compras</h1>
    
        </div>
    </div>
</app-base>