<div class="container-component">
    <h2 class="title">Musica</h2>
    <div class="music-content">
        <div class="left">
            <div class="left-content">
                <img class="album" src="assets/img/album.png">
                <div class="description">
                    <div class="title">Album: Sin Resentimientos</div>
                    <div class="desc-label">
                        <p class="label">Etiqueta:<span>Regional Mexicano</span></p>
                        <p class="label">Formato:<span>Descarga digital, CD</span></p>
                        <p class="label justify">Subject:<span>Sin Resentimientos narra la historia de una separación amorosa, con cada canción se vive una etapa diferente del duelo pasando por enojo, triste, dolor, resignacion y superacion, cada emocion acompañada del alcohol para ahogar las penas del alma.</span></p>
                    </div>
                </div>
                <div class="links">
                    <a href="https://open.spotify.com/artist/0cB5P2JVH6sZw9uA08dOPQ?si=kESfF7zNShKImxaVmneWPA" target="_blank">
                        <span class="socialImgContainer">
                            <img class="hyperSocialImg" src="assets/img/icons/spotify.png">
                        </span>
                    </a>
                    <a href="https://music.apple.com/mx/artist/nathan-solis/1648406680" target="_blank">
                        <span class="socialImgContainer">
                            <img class="hyperSocialImg" src="assets/img/icons/appleMusic.png">
                        </span>
                    </a>
                    <a href="https://www.youtube.com/channel/UCERl_WmQbBOIQVlThoebYJw" target="_blank">
                        <span class="socialImgContainer">
                            <img class="hyperSocialImg" src="assets/img/icons/youtube.png">
                        </span>
                    </a>
                    <a href="https://www.deezer.com/mx/artist/185630437" target="_blank">
                        <span class="socialImgContainer">
                            <img class="hyperSocialImg" src="assets/img/icons/deezer.png">
                        </span>
                    </a>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="opacity">
                <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/0cB5P2JVH6sZw9uA08dOPQ?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                <div class="nextSong"></div>

                                <!--

                
                <div class="header">
                    <div class="image-album-player">
                        <img *ngIf="!isPlaying" (click)="playAudio()" class="album-play-button" src="assets/img/play-audio-button.png">
                        <img *ngIf="isPlaying" (click)="pauseAudio()" class="album-play-button" src="assets/img/pause-audio-button.png">
                    </div>
                    <div class="play-buttons">
                        <audio #player controls src='assets/music/SiTeLargaste.mp3' type="audio/mp3">
                            Your browser does not support the <code>audio</code> element.
                        </audio>
                        <p class="author-player">NATHAN SOLIS</p>
                        <p class="title-player">Si te Largaste</p>
                        <div class="audio-buttons">
                            <input type="range" name="volume" #ranger (input)="changeVolume(ranger.value)" min="1" max="10">
                        </div>
                        <div class="player">

                            <div class="main">
                                <input type="range" name="volume" #rangerPlay (input)="changeSongMoment(rangerPlay.value)" min="0" max="60">
                                <div id="selector">
                                  <div class="selectBtn"></div>
                                  <div id="selectValue"></div>
                                </div>
                                <div id="progressBar"></div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="songs">
                    <div *ngFor="let song of songs">
                        <div class="song" [ngClass]="{'inactiveSong': !song.active}">
                            <div class="left-song">
                                <div class="song-title">{{song.title}}</div>
                                <div class="song-author">{{song.author}}</div>
                            </div>
                            <div class="right">
                                <div class="duration">{{song.time}}</div>
                            </div>
                        </div>    
                    </div>                   
                </div>
            -->
            </div>
        </div>
    </div>
</div>