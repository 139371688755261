<div class="container-component">
    <div class="form-container">       
        <h1 class="title">Redes Sociales</h1>
        <div class="media">
            <a href="https://open.spotify.com/artist/0cB5P2JVH6sZw9uA08dOPQ?si=FK6UEXLwR-OJHhb9EbHEjw" target="_blank">
                <div class="sm">
                    <img class="logo" src="assets/img/icons/spotify.png">
                </div>
            </a>
            <a href="https://www.youtube.com/channel/UCERl_WmQbBOIQVlThoebYJw" target="_blank">
                <div class="sm">
                    <img class="logo" src="assets/img/icons/youtube.png">
                </div>
            </a>
            <a href="https://www.instagram.com/nathansolisoficial/" target="_blank">
                <div class="sm">
                    <img class="logo" src="assets/img/icons/instagram.png">
                </div>
            </a>
            <a href="https://twitter.com/NatSolisOficial" target="_blank">
                <div class="sm">
                    <img class="logo" src="assets/img/icons/twitter.png">
                </div>
            </a>
            <a href="https://www.facebook.com/NathanSolisOficial/" target="_blank">
                <div class="sm">
                    <div class="sm"><img class="logo" src="assets/img/icons/facebook.png"></div>
                </div>
            </a>
        </div> 
        <div class="elfsight-app-7fd36aa1-7204-40b2-ad20-f44b206f5f9e"></div>
    </div>
</div>
