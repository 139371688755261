<div class="concert-container">
    <div class="concert-header">
        <img class="logo-lunario-nathan-solis" logoSize src="assets/img/concert/black-nathan-solis-logo.png">
        <div class="concert-text">
            <img class="logo-lunario" logoSize src="assets/img/concert/lunario.png">
            <p class="date">JUL 25</p>
            <p class="text-header">Mauricio Ramos en Concierto, invitado especial Nathan Solis. 
            Boletos a la venta en <a class="lunario-link" target="_blank" href="https://www.lunario.com.mx/cartelera/2024/julio/mauricio-ramos.html">TicketMaster</a> o taquillas del Auditorio Nacional.</p>
        </div>
    </div>
    <img class="nathan" logoSize src="assets/img/concert/700-nathan-solis.png">

</div>