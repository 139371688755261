<app-base>
    <div class="container-component">
        <section id="home" class="section">
            <!--
               <iframe style="border-radius:12px; padding: 0 15px; box-sizing: border-box;" src="https://open.spotify.com/embed/artist/0cB5P2JVH6sZw9uA08dOPQ?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>        
            -->
        </section>
        <section id="bio" class="section">
            <app-bio></app-bio>
        </section>
        <section id="music" class="section">
            <app-music></app-music>
        </section>
        <section id="news" class="section">
            <app-news></app-news>
        </section>
        <section id="concert" class="section">
            <app-concert></app-concert>
        </section>
        <section id="contact" class="section">
            <app-contact></app-contact>
        </section>
    </div>
</app-base>