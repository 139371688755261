<div class="container-component">
    <div class="bio-content">
        <div class="left">
            <img class="flyer" src="assets/img/nathanSolis.jpg">
        </div>
        <div class="right">
            <div class="opacity">
                <h2 class="title">Biografia</h2>
                <p class="p-br">La nueva promesa del regional mexicano</p>
                <p class="simple-text">
                    Nathan vino al mundo un 24 de agosto en Ciudad de México, donde creció rodeado de la rica cultura y tradiciones de la música mexicana. Desde temprana edad, su pasión por la música se hizo evidente. A medida que crecía, su amor por los icónicos géneros de la música regional mexicana, como el mariachi, la banda, el corrido y la ranchera, se profundizaba aún más.
                </p>
                <p class="simple-text">
                    Con el tiempo, Nathan comenzó a perfeccionar su talento mediante la práctica y la búsqueda de su propia voz artística.                     
                    Con el lanzamiento de su primer sencillo, "Si Te largaste", Solís inicio su carrera incursionando en el género bandariachi el cual fue bien recibido por el público.
                </p>
                <p class="simple-text">
                    Su pasión por preservar las tradiciones y el cálido recibimiento de la gente lo motivaron a seguir lanzando canciones entre las que destacan "Ni que fueras Tequila", "Amor de Borrachera", "Si el tequila hablara" entre otras.
                </p>
                <p class="simple-text">
                    Su viaje musical está lejos de terminar y pronto saldrán nuevas melodías que emanan del corazón de este apasionado intérprete de la música regional mexicana.
                </p>

                <div class="footer-bio">
                    <div class="signature">
                        <img class="signature" src="assets/img/Signature.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>