<div class="news-content">
    <div class="img-left"></div>
    <div class="central-content">
        <div class="central-r">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/xv4GHm2ZckM?si=arCISS99mE2SOqTn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class="central-l">
             <h2 class="lyrics">Ni que fueras tequila</h2><br/>
            <p class="lyrics">
                Dices que ya no soportas <br/>
                El estar conmigo <br/>
                Piensas que yo no soy mas <br/>
                Que un caso perdido <br/>
            </p>
            <p class="lyrics">
                El reclamo de siempre <br/>
                Conmigo no eres feliz <br/>
                La misma rutina <br/>
                De nuevo en tú papel de actriz <br/>
            </p>
            <p class="lyrics">
                Yo no dire nada <br/>
                Y si te vas <br/>
                Deja la puerta cerrada <br/>
                No quiero que te arrepientas <br/>
                Y vuelvas de nuevo <br/>
                Como si nada  <br/>
                Como si nada  <br/>
                Como si nada  <br/>
            </p>
            <p class="lyrics">
                Pues ni que fueras tequila <br/>
                Pa tomarte en serio <br/>
                Te vas, siempre vuelves <br/>
                La misma rutina <br/>
                Ya me se tu juego <br/>
            </p>
            <p class="lyrics">       
                Y ni que fueras tequila <br/>
                Pa estar en mis pedas <br/>
                Yo no te recuerdo <br/>
                Ni estando borracho <br/>
                Y te digo la neta <br/>
            </p>
            <p class="lyrics">       
                Ni que fueras tequila <br/>
                Pa traerme loco <br/>
                Por ti no siento nada  <br/>
                Y por tu falso amor <br/>
                Tampoco <br/>
            </p>
            <p class="lyrics">       
                Por ti no siento nada  <br/>
                Y por tu falso amor <br/>
                Tampoco <br/>
            </p>
        </div>
    </div>
    <div class="img-right"></div>
</div>