import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BaseComponent } from './utility/base/base.component';
import { HomeComponent } from './home/home.component';
import { APP_ROUTING } from './app.routes';
import { NavbarComponent } from './utility/navbar/navbar.component';
import { FooterComponent } from './utility/footer/footer.component';
import { BioComponent } from './bio/bio.component';
import { MusicComponent } from './music/music.component';
import { MarketComponent } from './market/market.component';
import { ContactComponent } from './contact/contact.component';
import { LandingComponent } from './landing-pages/landing/landing.component';
import { NewsComponent } from './news/news.component';
import { ConcertComponent } from './concert/concert.component';

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    BioComponent,
    MusicComponent,
    MarketComponent,
    ContactComponent,
    LandingComponent,
    ConcertComponent,
    NewsComponent,
    ConcertComponent
  ],
  imports: [
    BrowserModule,
    APP_ROUTING,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
